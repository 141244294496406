<template>
  <div class="list">
    <navbar :docsName="docsName"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <!-- Start FILTER -->

            <div class="row mb-2">
              <div
                class="block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row pt-4 pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filterVkurz"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>V-Kurzbezeichnung</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model.trim="filterVeranstaltung"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>Veranstaltung</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model.trim="filterNkurz"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>N-Kurzbezeichnung</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model.trim="filterNotenstruktur"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>Notenstruktur</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-2 pb-2">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filterCount"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>{{ $t("global.numberofrecords") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Ende FILTER -->
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="' Notenberechnung'"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <router-link
                      class="btn btn-success"
                      :to="{ name: 'Neue Notenberechnung' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-primary">
                      <font-awesome-icon
                        icon="fa-duotone fa-print"
                        class="mr-2"
                      />
                      Ausgabe
                    </button>
                  </div>
                  <div class="mr-2">
                    <button class="btn btn-primary">
                      <font-awesome-icon
                        icon="fa-regular fa-comments"
                        class="mr-2"
                      />
                      Kommunikation
                    </button>
                  </div>
                  <div class="mr-2">
                    <b-dropdown variant="primary">
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-cog"
                          class="mr-2"
                        />
                        <em>Aktionen</em>
                      </template>
                      <b-dropdown-item>First Action</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="notenberechnungsliste"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />
                      Notenberechnung löschen
                    </h4>
                  </div>
                  <div class="modal-body">
                    Wollen sie die ausgewählten Notenberechnungen wirklich
                    löschen?
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import InfiniteLoading from "vue-infinite-loading";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";

export default {
  name: "Notenberechnungliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Notenberechnung";
      },
    };
  },
  mixins: [page],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      page: 0,
      notenberechnungsliste: [],
      anzahlProPage: 50,

      filterVeranstaltung: this.$route.query.filterVeranstaltung,
      filterVkurz: this.$route.query.filterVkurz,
      filterNotenstruktur: this.$route.query.filterNotenstruktur,
      filterNkurz: this.$route.query.filterNkurz,
      filterCount: null,

      infiniteId: +new Date(),
      sortBy: "notenberechnung",
      sortDesc: false,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "veranstaltung[0].kuerzel",
          sortable: true,
          label: "V-Kurzbezeichnung",
        },
        {
          key: "veranstaltung[0].bezeichnung",
          sortable: true,
          label: this.$t("global.event"),
        },
        {
          key: "notenstruktur.kuerzel",
          sortable: true,
          label: "N-Kurzbezeichnung",
        },
        {
          key: "notenstruktur.bezeichnung",
          sortable: true,
          label: "Notenstruktur",
        },
        { key: "bezeichnung", sortable: true, label: "Bezeichnung" },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.filterVeranstaltung)
        params.filterVeranstaltung = this.filterVeranstaltung;

      if (this.filterVkurz) params.filterVkurz = this.filterVkurz;

      if (this.filterNotenstruktur)
        params.filterNotenstruktur = this.filterNotenstruktur;

      if (this.filterNkurz) params.filterNkurz = this.filterNkurz;
      if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },

    headline() {
      return "Notenberechnung";
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    filterVeranstaltung: function () {
      this.resetLoading(this);
    },
    filterVkurz: function () {
      this.resetLoading(this);
    },
    filterNotenstruktur: function () {
      this.resetLoading(this);
    },
    filterNkurz: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    },
  },
  mounted() {},
  methods: {
    details(notenberechnungsliste) {
      this.$router.push({
        path: "/bildung/notenberechnung/" + notenberechnungsliste.id,
      });
    },
    resetLoading: debounce((self) => {
      self.page = 0;
      self.notenberechnungsliste = [];
      self.infiniteId += 1;
    }, 500),
    loeschen() {
      Api.delete("notenberechnung/", { data: this.selectedIds }).then(
        (response) => {
          this.schliesseLoeschenModal();

          this.notenberechnungsliste = this.notenberechnungsliste.filter(
            (v) => !response.data.success.includes(v.id)
          );
        }
      );
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    infiniteHandler($state) {
      Api.get("notenberechnung/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.notenberechnungsliste.push(...response.data);
          $state.loaded();
        } else {
          this.notenberechnungsliste.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style></style>
